import React, { useContext } from 'react';

import './style.scss';
import { LangContext } from '../Layout';

const Principe = ({ principle, index }) => {
  const { language } = useContext(LangContext);
  return (
    <div className={index % 2 == 0 ? 'principe' : 'principe-middle'}>
      {principle.image && (
        <img
          src={principle.image.publicURL}
          alt={principle['title_' + language]}
          className="principe__image"
        />
      )}
      <div
        className="principe__title"
        style={{ color: index % 2 != 0 && '#EB514E' }}
      >
        {principle['title_' + language]}
      </div>
      <div className="principe__desc">{principle['desc_' + language]}</div>
    </div>
  );
};

const MethodologyMatters = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="methodology-matters">
      <div className="methodology-matters__title">
        {data['title_' + language]}
      </div>
      <div className="methodology-matters__subtitle">
        {data['subtitle_' + language]}
      </div>
      <div className="methodology-matters__principles">
        {data.principles.map((principle, index) => (
          <Principe key={index} principle={principle} index={index} />
        ))}
      </div>
    </div>
  );
};

export default MethodologyMatters;
