import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Brief from '../components/brief/Brief';
import MethodologyMatters from '../components/methodology-matters/MethodologyMatters';
import MethodologyFramework from '../components/methodology-framework/MethodologyFramework';
import CallToAction from '../components/call-to-action/CallToAction';
import ProjectTeam from '../components/project-team/ProjectTeam';

const MethodologyPageTemplate = ({ data }) => {
  const { markdownRemark: post } = data;
  const {
    title,
    brief,
    methodologyMatters,
    methodologyFramework,
    project,
    projectTeam,
  } = post.frontmatter;
  return (
    <Layout>
      <Brief data={brief} title={title} />
      <ProjectTeam data={projectTeam} />
      <MethodologyMatters data={methodologyMatters} />
      <MethodologyFramework data={methodologyFramework} />
      <CallToAction project={project} />
    </Layout>
  );
};

MethodologyPageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MethodologyPageTemplate;

export const methodologyPageQuery = graphql`
  query MethodologyPage {
    markdownRemark(frontmatter: { templateKey: { eq: "methodology-page" } }) {
      frontmatter {
        title
        description
        brief {
          title_en
          title_fr
          description_en
          description_fr
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
        methodologyMatters {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          principles {
            title_en
            title_fr
            desc_en
            desc_fr
            image {
              publicURL
            }
          }
        }
        projectTeam {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          jobs {
            title_en
            title_fr
            desc_en
            desc_fr
            image {
              publicURL
            }
          }
        }
        methodologyFramework {
          title_en
          title_fr
          close_en
          close_fr
          open_en
          open_fr
          steps {
            title_en
            title_fr
            desc_en
            desc_fr
          }
        }
        project {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          button_en
          button_fr
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
