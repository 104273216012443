import React, { useContext } from 'react';
import './style.scss';
import { LangContext } from '../Layout';

const Job = ({ job, index }) => {
  const { language } = useContext(LangContext);

  return (
    <div className="job">
      <img className="job__image" src={job.image.publicURL} />
      <div
        className="job__title"
        style={{ color: index != 1 && index != 2 && '#EB514E' }}
      >
        {job['title_' + language]}
      </div>
      <div className="job__desc">{job['desc_' + language]}</div>
    </div>
  );
};

const ProjectTeam = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="project-team">
      <div className="project-team__title">{data['title_' + language]}</div>
      <div className="project-team__subtitle">
        {data['subtitle_' + language]}
      </div>
      <div className="project-team__jobs">
        {data.jobs.map((job, index) => (
          <Job job={job} key={index} index={index} />
        ))}
      </div>
    </div>
  );
};

export default ProjectTeam;
