import React, { useContext, useState } from 'react';
import './style.scss';
import { LangContext } from '../Layout';
import CloseArrow from '../../img/close-arrow.inline.svg';
import OpenArrow from '../../img/arrow-open.inline.svg';
import ReactMarkdown from 'react-markdown';

const Step = ({ step, open, close, number }) => {
  const { language } = useContext(LangContext);

  const [show, setShow] = useState(300);

  const handleClick = () => {
    show === 300 ? setShow(10000) : setShow(300);
  };

  return (
    <div className="item">
      <div className="item__number">
        <div className="item__number__container">{number + 1}</div>
      </div>
      <div className="item__title">{step['title_' + language]}</div>
      <div className="item__desc">
        <ReactMarkdown source={step['desc_' + language].substring(0, show)} />
      </div>
      <div className="item__button" onClick={handleClick}>
        {show === 300 ? (
          <div className="read-more">
            {open} <OpenArrow className="arrow" />
          </div>
        ) : (
          <div className="close">
            {close} <CloseArrow className="arrow" />
          </div>
        )}
      </div>
    </div>
  );
};

const MethodologyFramework = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="methodology-framework">
      <div className="methodology-framework__title">
        {data['title_' + language]}
      </div>
      <div className="methodology-framework__steps">
        {data.steps.map((step, index) => (
          <Step
            step={step}
            key={index}
            number={index}
            open={data['open_' + language]}
            close={data['close_' + language]}
          />
        ))}
      </div>
    </div>
  );
};

export default MethodologyFramework;
